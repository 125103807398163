<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title {{ entity.id ? 'Edit entity' : 'Create legal entity' }}
			.widget-body
				form(@submit.prevent="submit")
					h4.widget-section-title General
					.row
						.col-sm-4(v-if="entity.id")
							.form-group
								label ID
								input.form-control(type="text", disabled="disabled", :value="entity.id")
						.col-sm-4
							.form-group
								label Legal Name
								input.form-control(type="text", v-model="entity.name")
						.col-sm-4
							.form-group
								label Common Name
								input.form-control(type="text", v-model="entity.common_name")

					.row
						.col-sm-8
							.form-group
								label Address
								input.form-control(type="text", v-model="entity.address")
						.col-sm-4
							.form-group
								label Country
								select.form-control(v-model="entity.country")
									option(v-for="o in countryOptions", :value="o.code") {{ o.name }}
					.row
						.col-sm-1
							.form-group(style="padding-top: 24px")
								b-form-checkbox(v-model="entity.vat") VAT
						.col-sm-3
							.form-group
								label VAT Number
								input.form-control(type="text", v-model="entity.vat_number")
						.col-sm-4
							.form-group
								label iCount Client &nbsp;
									i.la.la-info-circle(v-b-tooltip.hover.right, title="choose the corresponding client from your iCount account for generating invoices")
								multiselect(:options="externalOptions", v-model="entity.ext_id", track-by="v", label="t", deselect-label="", select-label="")

						.col-sm-4
							.form-group
								label Contact Email
								input.form-control(type="text", v-model="entity.contact_email")

					.row
						.col-sm-6
							.form-group
								label Comments
								textarea.form-control(v-model="entity.comments", rows="5")
						.col-sm-2
							.form-group
								label Advertiser Net Terms
								number-input(v-model="entity.advertiser_net_terms")
							.form-group
								label Publisher Net Terms
								number-input(v-model="entity.publisher_net_terms")
						.col-sm-4
							label IO Files
							doc-list(:files="entity.files")


					.row
						.col-sm-6
							.form-group
								v-select(:options="advertiserOptions", v-model.number="new_advertiser", label="t", @input="addAdvertiser()", placeholder="Add Advertisers")
							table.table
								thead
									tr
										th Associated Advertisers
										th(width="30px") &nbsp;
								tbody
									tr(v-for="(o, index) in selectedAdvertisers")
										td {{ o.t }}
										td.actions
											button.btn.btn-danger(type="button", @click="selectedAdvertisers.splice(index, 1)")
												i.la.la-trash
						.col-sm-6
							.form-group
								v-select(:options="publisherOptions", v-model.number="new_publisher", label="t", @input="addPublisher()", placeholder="Add Publishers")
							table.table
								thead
									tr
										th Associated Publishers
										th(width="30px") &nbsp;
								tbody
									tr(v-for="(o, index) in selectedPublishers")
										td {{ o.t }}
										td.actions
											button.btn.btn-danger(type="button", @click="selectedPublishers.splice(index, 1)")
												i.la.la-trash


					.buttons.text-right
						router-link.btn.btn-default(:to="{name:'legal-entity-list'}")
							i.la.la-angle-left
							| Back
						| &nbsp;
						button.btn.btn-primary(type="submit") Save
							i.la.la-fw.la-save
</template>
<script>
import Vue from 'vue';

export default {
  name: 'LegalEntityForm',
  async beforeRouteEnter(to, from, next) {
    let entity = {};
    if (to.name === 'legal-entity-edit') {
      let entityId = +to.params.id;
      try {
        entity = await Vue.ovData.legalEntity.get(entityId, true);
      } catch (e) {
        return next(false);
      }
    } else {
      entity = Vue.ovData.legalEntity.newInstance();
    }

    let advertiserOptions = await Vue.ovData.advertiser.getOptions(true);
    let publisherOptions = await Vue.ovData.publisher.getOptions(true);
    let externalOptions = await Vue.ovData.legalEntity.getExternalOptions();
    next(async (vm) => {
      vm.entity = entity;
      vm.countryOptions = await Vue.ovData.country.getOptions();
      vm.advertiserOptions = advertiserOptions;
      vm.publisherOptions = publisherOptions;
      vm.externalOptions = externalOptions;
      vm.selectedAdvertisers = entity.advertisers.map((o) => ({ v: o.id, t: o.name }));
      vm.selectedPublishers = entity.publishers.map((o) => ({ v: o.id, t: o.name }));
      if (vm.entity.ext_id) {
        vm.entity.ext_id = externalOptions.find((o) => +o.v === vm.entity.ext_id);
      }
      return vm;
    });
  },
  data() {
    return {
      busy: false,
      entity: {},
      countryOptions: [],
      advertiserOptions: [],
      publisherOptions: [],
      externalOptions: [],
      new_advertiser: null,
      new_publisher: null,
      selectedAdvertisers: [],
      selectedPublishers: [],
    };
  },
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let data = Vue.util.extend({}, this.entity);
      data.advertiser_ids = this.selectedAdvertisers.map((o) => o.v).join(',');
      data.publisher_ids = this.selectedPublishers.map((o) => o.v).join(',');
      data.ext_id = this.entity.ext_id ? this.entity.ext_id.v : null;
      try {
        await this.$ovReq.post('finance/legalEntity/save', data);
        this.busy = false;
        this.$ovNotify.success('Legal entity has been saved');
        this.$router.push({ name: 'legal-entity-list' });
      } catch (e) {
        this.busy = false;
      }
    },

    addAdvertiser() {
      if (this.selectedAdvertisers.map((o) => o.v).includes(this.new_advertiser.v)) {
        return;
      }
      this.selectedAdvertisers.push(this.new_advertiser);
      this.new_advertiser = null;
    },

    addPublisher() {
      if (this.selectedPublishers.map((o) => o.v).includes(this.new_publisher.v)) {
        return;
      }
      this.selectedPublishers.push(this.new_publisher);
      this.new_publisher = null;
    },
  },
};
</script>
